<template>
  <div class="tw-w-full">
<!--    <loader :loading="loading" v-if="loading"/>-->
<!--      <div v-else>-->
      <landing-page-nav-bar/>
   <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-justify-center tw-mt-20 lg:tw-mt-52">
       <div class="left-side tw-items-start tw-flex tw-justify-center tw-flex-col">
           <div class="side-big-ads" :style="{backgroundImage : `url(${ads2})`}">
               <div class="tw-flex tw-w-full tw-flex-col  tw-p-4 tw-absolute">
                   <h6 class="ads-text tw-mt-16">Advert</h6>
               </div>
             <div class="side-big-ads-content"></div>
           </div>
           <div class="side-small-ads tw-mt-8" :style="{backgroundImage : `url(${ads2})`}">
               <div class="tw-flex tw-w-full tw-flex-col  tw-p-4 tw-absolute">
                   <h6 class="ads-text tw-mt-16">Advert</h6>
               </div>
               <div class="side-small-ads-content"></div>
           </div>
       </div>
       <div class="tw-flex tw-flex-col  center-container tw-mx-5 lg:tw-mx-0">
           <div class="find-trip  tw-mb-10 lg:tw-mb-20 " :style="{backgroundImage : `url(${defaultBg})`}">
               Find Trip
           </div>
           <quick-search />
           <div class=" tw-flex tw-w-full tw-items-center tw-justify-center"  id="topDeals">

           <div class="tw-flex top-deals-container tw-flex-col tw-py-20 ">
              <h6 class="top-deals-header">Top Deals</h6>
             <loader v-if="loading" :loading="loading"/>
              <div class="tw-flex tw-w-full  tw-flex-wrap tw-justify-between" v-else>
                  <div class="tw-flex tw-w-full lg:tw-w-auto" v-for="(trip,index) in pendingTrips" :key="index">
                      <div class="tw-mt-10 top-deals-card lg:tw-mr-2" v-if="index+1 <= 9">

                      <img class="top-deals-card-img" :src="trip.routeDepartureTerminalImage" alt="Top deals" />
                      <div class="tw-flex tw-w-full tw-flex-col tw-px-6 tw-py-8">
                          <div class="tw-w-full tw-flex tw-flex-row tw-items-center">
                              <icons name="to-and-fro-short" :color="companyData.companyColor" />
                              <div class="tw-flex tw-flex-col tw-justify-between tw-ml-3">
                                  <h6 class="departure " style="text-transform: capitalize!important;">
                                      {{getFirstText(trip.routeDepartureTerminalName)}} {{getFirstText(trip.routeDepartureCity)}}</h6>
                                  <h6 class="arrival " style="text-transform: capitalize!important;">
                                      {{getFirstText(trip.routeDestinationTerminalName)}} {{getFirstText(trip.routeDestinationCity)}}</h6>
                              </div>
                          </div>
                          <div class="tw-flex tw-flex-row tw-w-full tw-mt-5 tw-justify-between tw-items-center" v-if="!trip.tripRegion || trip.tripRegion === 'local'">
                              <div class="tw-flex tw-flex-col tw-justify-between">
                                  <h6 class="arrival" v-if="trip.tripAmount">NGN{{trip.tripAmount.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})}}</h6>
                              </div>
                              <v-btn text class="book-btn text-capitalize" :style="{background:companyData.companyColor}" @click="routeToSelectedTrip(trip)">Book</v-btn>
                          </div>
                          <label v-if="!trip.tripRegion || trip.tripRegion === 'local'" class="take-off-date tw-mt-2">{{formatDate(trip.takeOffDate)}}</label>
                          <div class="tw-flex tw-flex-col tw-w-full tw-my-0 tw-justify-between tw-items-start" v-if="trip.tripRegion === 'crossCountry'">
                              <div class="tw-flex tw-flex-col tw-justify-between tw-w-full">
                                  <div class="right" v-if="trip.tripRegion === 'crossCountry'">
                                      <v-radio-group class="tw-flex tw-items-center"  v-model="trip.selectedTripCategory">
                                          <v-radio v-for="(tripCategory,index) in trip.tripCategories" :key="index" :color="companyData.companyColor" :value="tripCategory">
                                              <template #label>
                                                  <div class="trip-category text-capitalize tw-flex tw-flex-row">
                                                      <div class="tw-w-7/12">{{tripCategory.type}}</div>
                                                      <div class="tw-w-5/12">
                                                          &#8358;
                                                      {{tripCategory.tripAmount}}
                                                      </div>
                                                  </div>
                                              </template>
                                          </v-radio>
                                      </v-radio-group>
                                  </div>
                              </div>

                          </div>
                          <div class="tw-flex tw-flex-row tw-justify-between tw-items-center" v-if="trip.tripRegion === 'crossCountry'">
                              <label class="take-off-date tw-mt-2">{{formatDate(trip.takeOffDate)}}</label>
                              <v-btn text class="book-btn text-capitalize" :style="{background:companyData.companyColor}" @click="routeToSelectedTrip(trip)">Book</v-btn>
                          </div>


                      </div>
                      </div>
                  </div>
              </div>

           </div>
           </div>

           <div class="tw-flex tw-w-full tw-flex-col tw-pb-20 ">
               <v-divider :style="{backgroundColor: companyData.companyColor}"/>
                <div class="wide-ads tw-my-20  tw-self-center">
                   <img src="@/assets/pos.jpeg" alt="ads"  class="tw-flex tw-flex-col tw-w-full tw-my-0 tw-justify-between tw-items-start wide-ads-img">
                    <div class="tw-flex tw-p-6 tw-flex-col wide-ads-content">
                        <div class="tw-w-full tw-flex tw-justify-between tw-items-center">
                          <h6 class="wide-ads-header">Make Money As An Agent</h6>
                        </div>
                        <label class="wide-ads-desc tw-mb-0 lg:tw-mb-0">
                            Become a travel Agent in few minutes by simply signing up for free and begin to sell tickets for {{companyData.companyName}}.
                        </label>
                        <v-btn text class="book-btn text-capitalize" @click="routeToSignUp" :style="{background: companyData.companyColor}">Try It Out</v-btn>
                    </div>
                </div>
               <v-divider :style="{backgroundColor: companyData.companyColor}"/>
               <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center" >
               <div class="tw-flex top-deals-container tw-flex-col lg:tw-flex-row tw-items-start tw-justify-center tw-my-20">
                   <div class="company-mission tw-p-10 tw-mb-10 lg:tw-mb-0 lg:tw-mr-10">
                       <icons name="company-mission" :color="companyData.companyColor" />
                       <h6 class="top-deals-header">Our Mission</h6>
                       <label class="wide-ads-desc">
                           {{companyData.missionStatement}}.
                       </label>
                   </div>

                   <div class="company-mission tw-p-10">
                       <icons name="company-vision" :color="companyData.companyColor" />
                       <h6 class="top-deals-header">Our Vision</h6>
                       <label class="wide-ads-desc">
                           {{companyData.visionStatement}}.
                       </label>
                   </div>
                </div>
               </div>
               <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center   tw-mb-20" id="topCities">
                   <div class="tw-flex top-deals-container tw-flex-col lg:tw-py-20 ">
                       <h6 class="top-deals-header tw-mb-5 lg:tw-mb-0">Top Cities</h6>
                       <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-center">

                       <div class="tw-flex tw-w-full tw-flex-col tw-item-center lg:tw-pr-16">
                           <div class="city-card tw-mb-10" v-for="(terminalCity, index) in terminalCityTrips" :key="index">
                               <img :src="terminalCity.terminalImage" alt="city" class="city-card-img" />
                               <div class="tw-flex tw-flex-col city-card-content tw-p-5">
                                    <h6 class="city-card-header text-capitalize">{{toSentenceCase(terminalCity.terminalCity)}}</h6>
                                   <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-my-3">
                                       <icons name="save-label" color="#004ADE"  />
                                       <label class="city-card-desc tw-ml-3">{{terminalCity.totalTrips}} Trips</label>
                                   </div>
                                   <div class="tw-hidden tw-w-full tw-flex-row tw-items-center">
                                       <icons name="city" color="#C84867"  />
                                       <label class="city-card-desc tw-ml-3">{{terminalCity.totalTerminals}} Terminals</label>
                                   </div>
                               </div>
                           </div>

                       </div>
                           <img src="@/assets/nigeria-cities.svg" class="nigeria-cities" alt="Nigeria Cities">
                       </div>
                   </div>

               </div>
               <v-divider :style="{backgroundColor: companyData.companyColor}"/>
               <div class="tw-hidden tw-w-full tw-flex-col tw-justify-center tw-items-center tw-mb-20">
                   <div class="tw-flex top-deals-container tw-flex-col">
                       <h6 class="top-deals-header tw-mt-10">Try This Out</h6>
                       <div class="tw-flex tw-flex-row try-this-container tw-justify-between tw-py-8" >
                           <div class="ads1 tw-mr-10" :style="{backgroundImage: 'url('+ads.img+')'}" v-for="(ads,index) in adverts" :key="index">
                               <div class="tw-flex tw-w-full tw-flex-col  tw-p-4 tw-absolute">
                                   <h6 class="ads-text tw-mt-16">{{ads.title}}</h6>
                                   <label class="ads-text tw-mt-32">{{ads.desc}}</label>
                               </div>
                              <div class="ads1-content" />
                           </div>
                       </div>
                   </div>
               </div>
               <v-divider :style="{backgroundColor: companyData.companyColor}"/>

               <div class=" tw-flex tw-w-full tw-items-center tw-justify-center " id = "topTerminals">
                   <div class="tw-flex top-deals-container tw-flex-col tw-py-20 ">
                       <h6 class="top-deals-header">Top Terminals</h6>
                       <div class="tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-justify-between"  >
                           <div class="top-terminal-card tw-mt-10" v-for="(trip,index) in terminalTrips" :key="index">
                               <img class="top-deals-card-img" :src="trip.terminalImage" alt="Top deals" />
                               <div class="tw-flex tw-w-full tw-flex-col tw-px-3 tw-py-5">
                                   <div class="tw-w-full tw-flex tw-flex-row tw-items-center">
                                       <div class="tw-flex tw-flex-col tw-justify-between tw-ml-3">
                                           <h6 class="arrival">{{getFirstText(trip.terminalName)}}</h6>
                                           <h6 class=" departure">{{getFirstText(trip.terminalCity)}}</h6>
                                       </div>
                                   </div>
                                   <div class="tw-flex tw-flex-row tw-w-full tw-ml-3 tw-mt-5  tw-items-center">
                                       <icons name="save-label" color="green"/>
                                       <label class="take-off-date tw-ml-4">{{trip.totalTrips}} Trips</label>
                                   </div>
                               </div>
                           </div>
                       </div>

                   </div>
               </div>
           </div>

       </div>
       <div class="right-side tw-items-center tw-hidden tw-justify-center tw-flex-col">
           <div class="side-small-ads tw-mb-8" :style="{backgroundImage : `url(${ads2})`}">
               <div class="tw-flex tw-w-full tw-flex-col  tw-p-4 tw-absolute">
                   <h6 class="ads-text tw-mt-16">Advert</h6>
               </div>
               <div class="side-small-ads-content"></div>
           </div>
           <div class="side-big-ads" :style="{backgroundImage : `url(${ads2})`}">
               <div class="tw-flex tw-w-full tw-flex-col  tw-p-4 tw-absolute">
                   <h6 class="ads-text tw-mt-16">Advert</h6>
               </div>
               <div class="side-big-ads-content"></div>
           </div>

       </div>
   </div>

<!--      </div>-->
    <Footer />
  </div>
</template>

<script>
import QuickSearch from "@/views/QuickSearch";
import  defaultLogo from "@/assets/bluelogo.png"
import defaultBg from "@/assets/find-trip-bg.png"
import ads1 from "@/assets/ads1.png"
import ads2 from "@/assets/ads2.png"
import ads3 from "@/assets/ads3.png"
import {mapGetters} from "vuex";
// import Loader from "@/components/reuseables/Loader";
import Footer from "@/components/reuseables/Footer.vue";
import LandingPageNavBar from "@/components/reuseables/LandingPageNavBar.vue";
import Icons from "@/components/reuseables/Icons.vue";
import {getAllPendingTripsByPage,
  getCompanyBookingsPerTerminalCities,
  getCompanyTerminalStat
} from "@/services/api/APIService";
import dayjs from "dayjs";
import Loader from "@/components/reuseables/Loader.vue";
export default {
  name: "MakeBooking",
  data(){
    return{
      defaultLogo,
      defaultBg,
        ads1,
        ads2,
        adverts:[
            {
                img : ads1,
                title: "RoadPadi",
                desc: "Revolutionizing Mobility"
            },
            {
                img : ads2,
                title: "PayDay",
                desc: "Pay Anyway at Anytime"
            },
            {
                img : ads3,
                title: "Spotify",
                desc: "Music for the Soul"
            },
            {
                img : ads1,
                title: "RoadPadi",
                desc: "Revolutionizing Mobility"
            },
            {
                img : ads2,
                title: "PayDay",
                desc: "Pay Anyway at Anytime"
            },
            {
                img : ads3,
                title: "Spotify",
                desc: "Music for the Soul"
            },
        ],
      pendingTrips:[],
      terminalTrips:[],
      terminalCityTrips:[],
      loading: false
    }
  },
  components: {Loader, Icons, LandingPageNavBar, Footer,  QuickSearch},
  computed:{
    ...mapGetters("companyProfile", ["companyData"]),
  },
    watch:{
      'companyData':{
          handler: function (val){
              if (val && Object.keys(val).length){
                  this.getPendingTrips()
                  this.getBookingsByTerminals()
                  this.getBookingsByTerminalCities()
              }
          },
          immediate: true,
          deep : true
      }
    },
   methods:{
       sortObjectByTerminalKey(obj) {
           let terminalBookings = []
           for(const [key,value] of Object.entries(obj)) {
               let terminal = value
               terminal.terminalName = key
              terminalBookings.push(terminal)

           }
           terminalBookings.sort((a, b) =>    a.totalBookings - b.totalBookings)
        return terminalBookings.slice(-3)
       },
       sortObjectByTripKey(obj) {
           let terminalTrips = []
           for(const [key,value] of Object.entries(obj)) {
               let terminal = value
               terminal.terminalName = key
               terminalTrips.push(terminal)

           }
           terminalTrips.sort((a, b) =>    a.totalTrips - b.totalTrips)
           return terminalTrips.slice(-3)
       },
      getPendingTrips(){
           // let today = dayjs().format("YYYY-MM-DD")
          if (this.companyData && this.companyData.id) {
            this.loading = true
            let pageData = {
              transportCompanyId : this.companyData.id,
              page : 0,
              pageSize: 300
            }
            getAllPendingTripsByPage(pageData).then(res => {
                  this.pendingTrips = res.data
                let currentDateTime =    dayjs(this.getUserSelectedDate())
                  this.pendingTrips =this.pendingTrips.filter(trip => dayjs(trip.takeOffDate+trip.takeOffTime).isSame(currentDateTime) || dayjs(trip.takeOffDate+trip.takeOffTime).isAfter(currentDateTime))

                  this.pendingTrips.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt))
                  this.loading = false

              }).finally(() => this.loading = false)

          }
      },
       getBookingsByTerminals(){
          if (this.companyData && this.companyData.id){
              getCompanyTerminalStat(this.companyData.id).then(res => {
                  this.terminalTrips = this.sortObjectByTripKey(res.data)
              })
          }
       },
       getBookingsByTerminalCities(){
           if (this.companyData && this.companyData.id){
               getCompanyBookingsPerTerminalCities(this.companyData.id).then(res => {
                  this.terminalCityTrips = this.sortObjectByTripKey(res.data)
               })
           }
       },
      getFirstText(text){
          if (text) {
              let arrayText = text.split(" ")
              return this.toSentenceCase(arrayText[0])
          }
      },
       toSentenceCase(text){
           text = text.toLowerCase()
           return text.replace(text.charAt(0),text.charAt(0).toUpperCase())
       },
       formatDate(date){
           return dayjs(date).format("LL")
       },
       routeToSignUp(){
           window.open(process.env.VUE_APP_MAIN_FRONTEND_URL+"/mobile-agent/register", "_blank")
       },
     getUserSelectedDate () {
       return dayjs().format('YYYY-MM-DDTHH:mm:ss')
     },

     routeToSelectedTrip(trip){
           if (trip.tripRegion === 'crossCountry'){
               if (trip.selectedTripCategory){
                   sessionStorage.setItem("userSelectedCategory", JSON.stringify(trip.selectedTripCategory))
                   this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",trip );
                   this.$router.push({name: 'OneWaySelectSeats'})
               }
               else {
                   this.$displaySnackbar({
                       message: "Please Select Your Identification Category"
                   })
               }
           }else {
               this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",trip );
               this.$router.push({name: 'OneWaySelectSeats'})
           }
       },
   },
  async created() {
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    if(userData && Object.keys(userData).length){
      this.loading = true
      this.$store.dispatch("companyData/getCompanyProfileData",userData.id)
          .then(()=>this.loading=false)
    }
  }
}
</script>

<style scoped lang="scss">
.center-container{
  min-height: 100vh;
  height: auto;
  width: 60vw;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
}
.left-side{
  display: none;
  width: 12.5vw;
  @media screen and (max-width: 820px) {
    display: none;
  }
}
.right-side{
  display: none;
  width: 12.5vw;
  @media screen and (max-width: 820px) {
    display: none;
  }
}
.company-logo{
  width: auto;
  height: 40px;
}

.find-trip{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 118px;
  font-family: var(--fontInter);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: var(--offWhite);
  background-size: cover;
  background-position: center;
  background-color: rgba(38, 50, 56, 0.3);
  border-radius: 10px;
}

.theme--light.v-footer {
  background-color: var(--white);
}

.header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.top-deals-container{
  min-width: 60vw;
  max-width: 60vw;

  @media screen and (max-width: 820px) {
     min-width: 100%;
    max-width: 100%;
  }
}

.try-this-container{
    max-width: 60vw;
    overflow-x: scroll;
    overflow-y: hidden;
    @media screen and (max-width: 820px) {
        max-width: 100%;
    }
}
.try-this-container::-webkit-scrollbar {
    background: transparent !important;
    width: auto;
    height: 2px;
}
.try-this-container::-webkit-scrollbar-thumb {
    background: #c4c4c4 !important;
    max-height: 1px !important;
    border-radius: 10px;
}

.top-deals-card{
  display: flex;
  flex-direction: column;
  color: #FAFAFA;
  border-radius: 10px;
  height: 434px;
  width: 285px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  @media screen and (min-width: 1900px){
      width: 19.5vw;
      height: 570px;
  }

  @media screen and(max-width: 820px) {
    width: 100%;
    height: max-content;
  }
}

.top-terminal-card{
    display: flex;
    flex-direction: column;
    color: #FAFAFA;
    border-radius: 10px;
    height: 383px;
    width: 285px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    @media screen and (min-width: 1900px){
        width: 19.5vw;
        height: 470px;
    }
    @media screen and(max-width: 820px) {
        width: 100%;
    }
}
.top-terminal-card:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.top-deals-card:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.top-deals-header{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 56px;
  color: #263238;

    @media screen and (max-width: 820px) {
        line-height: 35px;
    }
}

.top-deals-card-img{
  width: 285px;
  height: 240px;
  object-fit: cover;
  border-radius: 10px;
    @media screen and (min-width: 1900px){
        width: 19.5vw;

    }
  @media screen and(max-width: 820px) {
    width: 100%;
  }
}

.departure{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #4F4F4F;
}
.arrival{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #4F4F4F;
}

.take-off-date{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #4F4F4F;
}

.book-btn{
  min-width: 100px !important;
  height: 32px;
  border-radius: 6px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  width: max-content;
}

.wide-ads{
  display: flex;
  flex-direction: row;
  width: 60vw;
  height: 245px;
  border: 1px solid rgba(29, 38, 45, 0.2);
  border-radius: 10px;
  color: #FFFFFF;

  @media screen and(max-width: 820px) {
    width: 100%;
    flex-direction: column;
    height: max-content;
  }
}
.wide-ads-img{
  height: 245px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  width: 335px;
  object-fit: cover;

  @media screen and(max-width: 820px) {
    width: 100%;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
.wide-ads-content{
  width: 560px;
  @media screen and(max-width: 820px) {
    width: 100%;
  }
}
.wide-ads-header{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 48px;
  color: #1D262D;
}
.wide-ads-desc{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #2A466C;
  min-height: 100px;
}

.company-mission{
  width: 438px;
  min-height: 450px;
  border: 1px solid rgba(79, 79, 79, 0.19);
  border-radius: 10px;

  @media screen and(max-width: 820px) {
    width: 100%;
  }
}

.city-card{
  display: flex;
  flex-direction: row;
  width :449px;
  height :148px;
  color: #FAFAFA;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;

    @media screen and (min-width: 1900px){
        width: 500px;
        height: 168px;
    }

  @media screen and (max-width: 820px) {
      width: 100%;
  }
}
.city-card-content{
  width: 227px;

    @media screen and (max-width: 820px) {
        width: 60%;
    }
}
.city-card-img{
  width: 222px;
  height: 148px;
  border-radius: 10px;
  object-fit: cover;
    @media screen and (min-width: 1900px){

        height: 168px;
    }
    @media screen and (max-width: 820px) {
        width: 40%;
    }
}
.city-card-header{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #4F4F4F;
}
.city-card-desc{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
}
.nigeria-cities{
    width: 393px;
    height: 484px;
    object-fit: cover;

    @media screen and (max-width: 820px) {
        width: 100%;
        height: 480px;
        object-fit: none;
        margin-top: 4rem;
    }
}

.ads1{
    background-repeat: no-repeat;
    background-position: center;
    min-width: 285px;
    height: 316px;
    border-radius: 10px;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 820px) {
        min-width: 100%;
    }
}

.side-big-ads{
    background-repeat: no-repeat;
    background-position: center;
    min-width: 196px;
    height: 230px;
    border-radius: 10px;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 820px) {
        min-width: 100%;
    }
}
.side-big-ads-content{
    min-width: 196px;
    height: 230px;
    border-radius: 10px;
    background-color: gray;
    opacity: 0.4;
    position: absolute;
    z-index: 0;


    @media screen and (max-width: 820px) {
        min-width: 100%;
    }
}

.side-small-ads{
    background-repeat: no-repeat;
    background-position: center;
    min-width: 196px;
    height: 146px;
    border-radius: 10px;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 820px) {
        min-width: 100%;
    }
}
.side-small-ads-content{
    min-width: 196px;
    height: 146px;
    border-radius: 10px;
    background-color: gray;
    opacity: 0.4;
    position: absolute;
    z-index: 0;
    @media screen and (max-width: 820px) {
        min-width: 100%;
    }
}

.ads1-content{
    width: 285px;
    height: 316px;
    border-radius: 10px;
    background-color: gray;
    opacity: 0.25;
    position: absolute;
    z-index: 0;


    @media screen and (max-width: 820px) {
        min-width: 100%;
    }
}
.ads-text{
    font-family: 'Raleway',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    opacity: 1!important;
    position: absolute;
}

.trip-category {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
    width: 100%;
    @media (max-width:960px) {
        font-size: 11px;
    }
}
</style>